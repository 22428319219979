<template>
  <div id="page-user-list">

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>
    <!-- Add Record -->
    <b-modal
      v-model="popActive"
      centered
      hide-footer
      :title="`Add Record`"
      class="bg-danger"
      size="lg"
    >
      <!-- <h1>{{$route.name}}</h1> -->
      <div
        v-if="$route.name === 'candidates-data'"
        class="vx-col w-full"
      >
        <b-row class="px-2">
          <h6>Fill in candidate informations</h6>
        </b-row>

        <DataProcessorCandidatesDataForm
          ref="refDataProcessorCandidatesDataForm"
          :edit-record="editDataManagerRow"
          @submitted="submitted"
        />
      </div>

      <div
        v-if="$route.name === 'jobs-data'"
        class="vx-col w-full"
      >
        <b-row class="px-2">
          <h6>Fill in job informations</h6>
        </b-row>

        <JobForm
          :job-id="job_id"
          :show-interview-input="showInterviewInput"
          :edit-record="editDataManagerRow"
          @form-data="updateFormData"
          @submitted="submitted"
        />
      </div>

      <div
        v-if="['applications-data', 'events-data'].includes($route.name)"
        class="vx-col w-100"
      >
        <b-row class="px-2">
          <h6>Fill in applications informations</h6>
        </b-row>

        <div
          class="p-2 d-flex flex-wrap align-items-center justify-contents-center w-100"
        >
          <div class="w-50 pr-1">
            <label>Applicant</label>

            <BotUserSearch
              id="user-name"
              ref="refBotUserIdSelect"
              v-model="applicationData.applicant_id"
              name="user-name"
              :multiple="false"
              @user-selected="selectUser"
            />
          </div>

          <div class="w-50 pl-1">
            <label>Job</label>

            <BotJobSearch
              id="job-name"
              ref="refBotJobIdSelect"
              v-model="applicationData.job_id"
              name="job-name"
              :multiple="false"
              @job-selected="selectJob"
            />
          </div>

          <div
            v-if="$route.name === 'events-data'"
            class="w-50 mt-1 pr-1"
          >
            <label>Interview Date & Time</label>
            <b-form-group
              label-for="interview_date_time"
            >
              <flat-pickr
                v-model="applicationData.interview_date_time"
                class="form-control"
                :config="dateTimeConfig"
                placeholder="Interview Date & Time"
              />
            </b-form-group>
          </div>

          <div
            v-if="$route.name === 'events-data'"
            class="w-50 mt-1 pl-1"
          >
            <b-form-group
              label="Interview Status"
              label-for="interview_status"
            >
              <b-form-input
                id="interview_status"
                v-model="applicationData.interview_status"
                class="w-full"
                placeholder="Interview Status"
              />
            </b-form-group>
          </div>

          <div
            v-if="!$isEmpty(applicationData.job_questions)"
            class="mt-3 w-100"
          >
            <h4>Questions</h4>
            <div
              v-for="(question, idx) in applicationData.job_questions"
              :key="question.key"
              class="mt-2 d-flex flex-row w-100 align-items-center"
            >
              <label
                class="w-25"
              >{{ question.question }}</label>
              <b-form-input
                v-model="applicationData.job_questions[idx]['answer']"
                class="w-75"
              />
            </div>
          </div>

          <JobPopup
            :activate-popup="popup_quiz"
            :job-questions="popup_questions"
            :save-nric="save_nric"
            :all-applications="registered"
            @application-form="checkForm"
            @application-id="setApplicationId"
          />

          <div class="w-100 mt-3 d-flex justify-content-end">
            <b-button
              variant="primary"
              @click="submitApplicationData"
            > Submit</b-button>
          </div>
        </div>
      </div>

      <!-- <div v-if="$route.name === 'events-data'" class="vx-col w-100">
        <b-row class="px-2">
          <h6>Fill in event informations</h6>
        </b-row>

        <div
          class="p-2 d-flex flex-wrap align-items-center justify-contents-center w-100"
        >
          <DataProcessorEventsDataForm
            @submitted="popActive = !popActive"
          />
        </div>
      </div> -->
    </b-modal>

    <!-- Broadcast -->
    <b-modal
      v-model="showBroadcastModal"
      centered
      hide-footer
      :title="`Broadcast`"
      class="bg-danger"
    >
      <div class="vx-col w-full">
        <b-row class="mx-1 my-2">
          <b-form-textarea
            rows="4"
            class="w-full border-danger"
            placeholder="Message"
          />
        </b-row>
        <b-row class="mx-1 my-2">
          <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >SMS</b-form-checkbox>
          <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >Email</b-form-checkbox>
          <b-form-checkbox
            name="app_boost_gurantee"
            class="mx-2"
            value="application"
          >Telegram</b-form-checkbox>
        </b-row>
        <b-row class="mx-1 my-2 float-right">
          <b-button variant="primary">
            Broadcast
          </b-button>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-model="showFullText"
      centered
      :title="fullTextTitle"
      ok-title="Close"
      ok-only
    >
      <b-card-text>
        <p style="white-space: pre-line;">
          {{ fullText }}
        </p>
      </b-card-text>
    </b-modal>

    <b-card class="py-1">
      <div class="d-flex justify-center w-100">
        <div
          v-if="show_switch"
          class="mx-auto"
        >
          <p
            v-if="$store.state.auth.ActiveUser.company_name === 'PersolKelly'"
            class=""
          >
            {{ which_data ? "My Data" : "All Data" }}
          </p>
          <b-form-checkbox
            v-if="$store.state.auth.ActiveUser.company_name === 'PersolKelly'"
            v-model="which_data"
            switch
            inline
            class="mx-auto w-36 h-8"
            variant="primary"
            :disabled="switch_disable"
          />
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-end mb-1">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <b-row
          class="d-flex mr-md-0 w-80 mt-1 mt-md-0"
          :class="{'justify-content-between': isTablet, 'justify-content-end': (!isTablet && !isMobile)}"
        >
          <!-- <b-col
            col
            cols="12"
            xl="auto"
            md="3"
            class="mt-0 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-form-input
              v-model="searchQuery"
              class="mb-1 mb-md-0"
              :style="`height: 40px; ${ isMobile ? 'width: 100%;' : 'min-width: 300px;;' }`"
              placeholder="Search..."
              @input="updateSearchQuery"
            />
          </b-col> -->
          <b-col
            col
            cols="12"
            md="auto"
            class="mt-1 mt-xl-0 mt-md-1 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              v-if="!hideResume"
              id="button-with-loading"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 200px' }`"
              variant="primary"
              @click="extractResumes"
            >Extract Resume & CSV</b-button>
          </b-col>
          <b-col
            v-if="!hideAction"
            id="actions"
            col
            cols="12"
            md="auto"
            class="mt-1 mt-xl-0 mt-md-1 px-md-0 pl-sm-0 pl-md-1"
            :class="{'w-100': isMobile}"
          >
            <b-dropdown
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 180px' }`"
              class="cursor-pointer"
              :class="{'w-100': isMobile}"
              split
              :variant="action_view"
              :text="action_text"
              right
              :icon="action_icon"
              type="filled"
              @click="performAction"
            >
              <b-dropdown-item @click="changeAction('Actions')">
                <span class="">
                  <feather-icon
                    icon="InfoIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Actions</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'write') && !['crm', 'biweekly', 'scheduled_jobs'].includes(getCurrentTable())"
                @click="changeAction('Add Record')"
              >
                <span class="">
                  <feather-icon
                    icon="PlusIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Add Record</span>
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'delete')"
                @click="changeAction('Delete Record')"
              >
                <span class="">
                  <feather-icon
                    icon="TrashIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Delete Record</span>
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$checkRights(this.$route.meta.section, 'write')"
                @click="changeAction('Broadcast')"
              >
                <span class="">
                  <feather-icon
                    icon="SpeakerIcon"
                    svg-classes="h-4 w-4"
                    class="mr-2"
                  />
                  <span class="w-32">Broadcast</span>
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col
            col
            cols="12"
            md="auto"
            class="mt-1 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              variant="primary"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
              @click="exportToCSV"
            >Export as CSV</b-button>
          </b-col>
          <b-col
            v-if="['events', 'applications'].includes(getCurrentTable())"
            col
            cols="12"
            md="auto"
            class="mt-1 mt-md-1 mt-xl-0 px-md-0 pl-sm-0 pl-md-1"
          >
            <b-button
              variant="primary"
              :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
              @click="exportToCSVAndResumes"
            >Export CSV & Resumes</b-button>
          </b-col>
        </b-row>
        <!-- ACTION - DROPDOWN -->
      </div>
      <!-- <FormGen
          :form-details="formData"
          :popup-active="popActive"
          @closePopup="popActive = false"
        /> -->
      <!-- Table -->
      <b-alert
        v-if="selectedRows && selectedRows.length > 0"
        show
        variant="primary"
        class="my-1 p-1"
        icon-pack="feather"
        icon="icon-info"
      >
        <div class="d-flex flex-row flex-gap-md justify-content-start align-items-center">
          <div>
            <b-button
              variant="primary"
              @click="selectAllRows"
            >Select all {{ totalRecords }} rows</b-button>
          </div>
          <div>
            <b>
              {{ serverParams.selectedAllRows ? totalRecords : selectedRows.length }} rows selected
            </b>
          </div>
        </div>
      </b-alert>
      <div
        v-if="isLoading && firstRun"
        class="d-flex justify-content-center"
        style="height: 100px; margin-top: 30px;"
      >
        <b-spinner
          style="width: 3rem; height: 3rem;"
        />
      </div>
      <vue-good-table
        v-else
        ref="data_manager"
        mode="remote"
        :pagination-options="{
          enabled: true,
          setCurrentPage: serverParams.page,
          perPage: serverParams.perPage,
          perPageDropdown: [5, 10, 20, 50, 100, 1000],
          dropdownAllowAll: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: null,
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading.sync="isLoading"
        :total-rows="totalRecords"
        :rows="dataRows"
        :columns="columnDefs"
        style-class="vgt-table condensed bordered"
        compact-mode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >
        <template
          slot="column-filter"
          slot-scope="props"
        >
          <span
            v-if="['date_of_birth', 'applicant_date_of_birth', 'created_on', 'applied_on', 'event_start_date', 'event_end_date', 'interview_date_time'].includes(props.column.field)"
            class="mt-2"
          >
            <b-button
              variant="warning"
              @click="showDateRangeFilter(true, props.column.field)"
            >
              Click to filter the date
            </b-button>
            <b-button
              v-if="props.column.field in serverParams.columnFilters"
              class="ml-2"
              @click="resetDateRangeFilter(true, props.column.field)"
            >Reset</b-button>
          </span>

          <span
            v-else-if="['preferred_industry'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="prefIndustry"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['job_industry'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="jobIndustryOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['preferred_location', 'applicant_preferred_location'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="prefWorkLocation"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['employment_type'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="employmentTypeOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['industry', 'applicant_preferred_industry'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="prefIndustry"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['published_fields'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="publishedFieldOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['job_functions'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="jobFunctionsOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['applicant_preferred_position'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="preferredPositionOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="['preferred_position'].includes(props.column.field)"
            class="mt-2"
          >
            <v-select
              :placeholder="`Filter ${props.column.label}`"
              multiple
              label="text"
              :options="preferredPositionOptions"
              class="bg-white vgt-filter-input-style"
              :reduce="(value) => value.value"
              @input="updateCustomFilter(props.column.field, $event)"
            />
          </span>

          <span
            v-else-if="props.column.field === 'applied_on'"
            class="mt-2"
          >
            <flat-pickr
              v-model="applied_on_filter"
              :config="configPicker"
              class="vgt-input"
              placeholder="Filter Added On"
              @input="(value) => updateFilters(props.column.field, value)"
            />
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'actions'"
            class="scroll-container "
          >
            <CellRendererActions
              :params="props.row"
              @edit-record="editRecord"
            />
          </span>

          <span
            v-else-if="props.column.field === 'job_description'"
            class="scroll-container "
          >
            <template
              v-if="props.row[props.column.field] && props.row[props.column.field].length >= 50"
            >
              <p>{{ props.row[props.column.field] && props.row[props.column.field].slice(0, 50) + '.....' }}</p>
              <b-badge
                variant="warning"
                class="mr-1"
                @click="activateFullText(props.row[props.column.field], 'Job Description')"
              >Read More</b-badge>
            </template>
            <template
              v-else
            >
              {{ props.row[props.column.field] }}
            </template>
          </span>

          <span
            v-else-if="props.column.field === 'job_requirements'"
            class="scroll-container "
          >
            <template
              v-if="props.row[props.column.field] && props.row[props.column.field].length >= 50"
            >
              <p>{{ props.row[props.column.field] && props.row[props.column.field].slice(0, 50) + '.....' }}</p>
              <b-badge
                variant="warning"
                class="mr-1"
                @click="activateFullText(props.row[props.column.field], 'Job Requirements')"
              >Read More</b-badge>
            </template>
            <template
              v-else
            >
              {{ props.row[props.column.field] }}
            </template>
          </span>

          <span
            v-else-if="props.column.field === 'job_type'"
            class="scroll-container"
          >
            <b-badge
              v-if="props.row.job_type"
              variant="success"
              class="mr-1"
            >{{ props.row.job_type === 'fulltime' ? 'Full-Time': 'Part-Time' }}</b-badge>
            <div v-else>
              -
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'active'"
            class="scroll-container"
          >
            {{ props.row.active ? 'Yes': 'No' }}
          </span>

          <span
            v-else-if="props.column.field === 'job_interview_type'"
            class="scroll-container"
          >
            <p class="p-0 m-0">{{ interviewTypes[props.row.job_interview_type] }}</p>
          </span>

          <span
            v-else-if="['employment_type', 'applicant_preferred_location', 'preferred_location',
                        'preferred_industry', 'preferred_job_type', 'applicant_preferred_job_type', 'preferred_type', 'preferred_position',
                        'industry', 'published_fields', 'post_channel'].includes(props.column.field)"
            class="scroll-container"
          >
            <div
              class="d-flex flex-wrap flex-gap-sm"
            >
              <b-badge
                v-for="(item, index) in props.row[props.column.field]"
                :key="props.row.id + '_' + props.column.field + '_' + index"
                variant="primary"
                class="text-wrap"
              >{{ $removeUnderscore(item.replace("'", "").replace('""', "").replace("'", "")) }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'job_questions'"
            class="scroll-container"
          >
            <div
              v-if="props.row.job_questions !== '-'"
              class="d-flex flex-wrap flex-gap-lg"
            >
              <span

                v-for="(item, index) in props.row.job_questions"
                :key="props.row.id + 'job_questions' + index"
                variant="warning"
                class=""
              >{{ index+1 +'. '+item }} </span>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'answered_questions'"
            class="scroll-container"
          >
            <div
              v-if="props.row[props.column.field] !== '-'"
              class="d-flex flex-wrap flex-gap-lg"
            >
              <span
                v-for="(item, index) in props.row[props.column.field]"
                :key="props.row.id + props.column.field + index"
                variant="warning"
                class=""
              >{{ `${index+1}. ${item['question']}: ${item['answer']}` }} </span>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'applicant_preferred_position'"
            class="scroll-container"
          >
            <div
              class="d-flex flex-wrap flex-gap-sm"
            >
              <b-badge
                v-for="(item, index) in props.row.applicant_preferred_position"
                :key="props.row.id + '_pref_position_' + index"
                variant="warning"
                class="text-wrap"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'applicant_preferred_industry'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div class="d-flex flex-wrap flex-gap-sm">
              <b-badge
                v-for="(item, index) in props.row.applicant_preferred_industry"
                :key="props.row.id + '_pref_industry_' + index"
                variant="dark"
                class="text-wrap"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'job_industry'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div

              class="d-flex flex-wrap flex-gap-sm"
            >
              <b-badge
                v-for="(item, index) in props.row.job_industry"
                :key="props.row.id + '_job_industry_' + index"
                variant="warning"
                class="text-wrap"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'job_functions'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              class="d-flex flex-wrap flex-gap-sm"
            >
              <b-badge
                v-for="(item, index) in props.row.job_functions"
                :key="props.row.id + '_function_' + index"
                variant="primary"
                class="text-wrap"
              >{{ item }}</b-badge>
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'interview_status'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              v-if="props.row.interview_status"
              class="d-flex flex-wrap"
            >
              <!-- {{ props.row.interview_status.application_status }} - {{ props.row.interview_status.note }} -->
              {{ props.row.interview_status }}
            </div>
          </span>

          <span
            v-else-if="props.column.field === 'follow_up_answers'"
            class="scroll-container"
            style="width: 300px;"
          >
            <div
              v-for="(value, key, index) in props.row.follow_up_answers"
              :key="key"
              class="d-flex flex-wrap"
            >

              {{ index + 1 }}. {{ key }}: {{ value }}
            </div>
          </span>

          <span
            v-else-if="['created_on', 'applied_on', 'interview_date_time'].includes(props.column.field)"
            class="scroll-container"
            style="width: 300px;"
          >
            {{ $formatDatetime(props.formattedRow[props.column.field], true) }}
          </span>

          <span
            v-else-if="['date_of_birth', 'applicant_date_of_birth', 'event_start_date', 'event_end_date'].includes(props.column.field)"
            class="scroll-container"
            style="width: 300px;"
          >
            {{ $formatDate(props.formattedRow[props.column.field]) }}
          </span>

          <span
            v-else-if="['event_start_time', 'event_end_time'].includes(props.column.field)"
            class="scroll-container"
            style="width: 300px;"
          >
            {{ $formatTime(props.formattedRow[props.column.field]) }}
          </span>

          <!-- Column: Common -->
          <span
            v-else
            class="scroll-container"
          >
            {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
          </span>
        </template>

      </vue-good-table>

    </b-card>

    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { format } from 'date-fns'
// Store Module
import { WritableStream } from 'web-streams-polyfill/ponyfill'
import streamSaver from 'streamsaver'
import FormGen from '@/layouts/components/FormGenerator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { completionMixins } from '@/components/mixins/CompletionMixins'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { VueGoodTable } from 'vue-good-table'

import {
  allIndustries, highestQualifications, allCitizenships, prefWorkLocationOptions,
} from '@/constants/datastore'
import {
  cloneDeep, debounce, difference, orderBy,
} from 'lodash'

import CompanySearch from '@/components/CompanySearch.vue'
import BotUserSearch from '@/components/BotUserSearch.vue'
import BotJobSearch from '@/components/BotJobSearch.vue'
import JobForm from '@/components/jobs/JobForm.vue'
import JobPopup from '@/components/JobPopup.vue'

import {
  BCard,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BFormInvalidFeedback,
  BPagination,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormRow,
} from 'bootstrap-vue'

// Cell Renderer

import flatPickr from 'vue-flatpickr-component'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import 'flatpickr/dist/flatpickr.css'

import DataProcessorCandidatesDataForm from './components/DataProcessorCandidatesDataForm.vue'
import DataProcessorEventsDataForm from './components/DataProcessorEventsDataForm.vue'

export default {
  components: {
    VueGoodTable,
    vSelect,
    FormGen,
    BCard,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormRow,
    BFormInvalidFeedback,

    vSelect,
    flatPickr,

    JobForm,
    JobPopup,
    DataProcessorCandidatesDataForm,
    DataProcessorEventsDataForm,
    CompanySearch,
    BotUserSearch,
    BotJobSearch,

    CellRendererActions,
  },

  mixins: [completionMixins, onResizeMixins],
  data() {
    return {
      renderFilter: false,
      dateRangeFilter: false,
      dateRangeField: null,
      showFullText: false,
      fullText: '',
      fullTextTitle: '',
      totalRecords: 0,
      dataRows: [],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },
      prefered_position: null,

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
        selectedAllRows: false,
      },
      selectedRows: null,
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
      applied_on_filter: null,

      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,
      applied_on_filter: null,

      showBroadcastModal: false,
      isLoading: true,
      show_switch: false,
      switch_disable: true,
      which_data: true,
      edit_mode: false,
      blur_check: 'blur',
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',
      items_deletion: [],
      records: [],
      columns: [],
      main_columns: [],
      shared_columns: [],
      formData: [],
      userids: [],
      popActive: false,
      hideAction: false,
      hideResume: true,
      all_inputs: {},
      // Filter Options
      searchQuery: '',
      startEndDate: null,
      masks: {
        input: 'YYYY-MM-DD',
      },

      // field input options
      allGenders: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
        { text: 'Others', value: 'Others' },
      ],

      employmentStatus: [
        { text: 'Unemployed for less than 6 months', value: 'Unemployed for less than 6 months' },
        { text: 'Unemployed for more than 6 months', value: 'Unemployed for more than 6 months' },
        { text: 'Employed', value: 'Employed' },
      ],

      citizenship: [
        { text: 'Singaporean', value: 'Singaporean' },
        { text: 'Singaporean PR', value: 'Singaporean PR' },
        { text: 'Others', value: 'Others' },
      ],

      all_availability: [
        { text: 'Days', value: 'Days' },
        { text: 'Weeks', value: 'Weeks' },
        { text: 'Months', value: 'Months' },
      ],

      prefWorkLocation: prefWorkLocationOptions,
      interviewTypes: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },

      interviewTypeOptionss: [
        { value: 'speed_interview', text: 'Virtual Speed Interview' },
        { value: 'virtual', text: 'Virtual Interview' },
        { value: 'physical', text: 'In-Person Interview' },
        { value: 'none', text: 'Not Specified' },
      ],
      employmentTypeOptions: [
        { text: 'Freelance', value: 'freelance' },
        { text: 'Internship Traineeship', value: 'internship_traineeship' },
        { text: 'Flexiwork', value: 'flexiwork' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Temporary', value: 'temporary' },
        { text: 'PRN', value: 'prn' },
      ],
      salaryCycleOptions: [
        { text: 'Yearly', value: 'Yearly' },
        { text: 'Monthly', value: 'Monthly' },
        { text: 'Weekly', value: 'Weekly' },
        { text: 'Daily', value: 'Daily' },
        { text: 'Hourly', value: 'Hourly' },
      ],
      preferredJobTypeOptions: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
      ],
      preferred_position_options: [],
      prefIndustry: [],
      preferredPositionOptions: [],
      publishedFieldOptions: [
        { text: 'Vacancies', value: 'vacancies' },
        { text: 'Salary', value: 'salary' },
        { text: 'Employer', value: 'employer' },
        { text: 'Recruiter', value: 'recruiter' },
      ],
      activeOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      applicantPreferredPositionOptions: [],
      jobIndustryOptions: [],
      jobFunctionsOptions: [],

      teamOptions: [],
      unionOptions: [],
      applicantTeamOptions: [],
      applicantUnionOptions: [],

      columnDefs: [],
      firstRun: true,

      exportingCSVIndex: null,
      exportingCSV: false,
      exportingCSVAndResumes: false,
      isDataFetched: false,

      highestQualifications: [],
      applicationData: {
        answers: [],
      },
      all_applicant_options: [
      ],
      all_job_options: [
      ],

      job_id: null,
      showInterviewInput: false,

      dateTimeConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },

      // for applicatinn form
      popup_quiz: false,
      popup_questions: [],
      save_nric: false,
      registered: [],

      editDataManagerRow: null,
    }
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      return 50
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      },
    },
  },
  watch: {
    startEndDate(val) {
      const column = this.dateRangeField

      const startDate = format(val.start, 'yyyy-MM-dd')
      const endDate = format(val.end, 'yyyy-MM-dd')

      const value = { from: startDate, to: endDate }
      this.updateFilters(column, value)
      this.dateRangeFilter = false
    },
    which_data(val) {
      this.getRecords()
    },
    'serverParams.columnFilters': {
      handler(oldVal, newVal) {
      },
      deep: true,
    },
    'applicationData.job_id': {
      // handler(newVal, oldVal) {
      //   this.getJobInfo(newVal)
      // },
      // deep: true,
    },

    '$route.name': function (to, from) {
      this.changeAction('Actions')
      this.columnDefs = []
      this.dataRows = []
      this.firstRun = true
      this.exportingCSV = false
      this.serverParams = {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
        selectedAllRows: false,
      }
      this.serverParams.extractCSV = false
      this.selectedRows = []
      this.getRecords()
    },

    isMobile(val) {
      if (val) {
        this.setColumnPinnedState('_id', null)
        this.setColumnPinnedState('email', null)
        this.setColumnPinnedState('Application ID', null)
      } else {
        this.setColumnPinnedState('_id', true)
        this.setColumnPinnedState('email', true)
        this.setColumnPinnedState('Application ID', true)
      }
    },
  },
  mounted() {
    // this.gridRowHeight = 2.8 * parseFloat(getComputedStyle(document.getElementById('agGridTable')).fontSize)
    const incompleteProfile = this.checkProfileCompletion()

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }

    // setTimeout(() => {
    //   this.showBroadcastModal = true
    // }, 3000)

    // this.$toast(
    //   {
    //     component: this.$toastContent,
    //     props: {
    //       title: 'Broadcast sent',
    //       icon: 'AlertCircleIcon',
    //       text: 'Broadcast has been sent to 11 users',
    //       variant: 'success',
    //     },
    //   },
    // )
  },
  created() {
    this.blur_check = ''
    if (this.$store.state.auth.ActiveUser.company_name !== 'PersolKelly') {
      this.which_data = false
      this.show_switch = false
    } else {
      this.show_switch = true
      this.which_data = true
      if (
        this.$store.state.auth.ActiveUser.user_group.includes(this.$UserGroups.SUPERUSER)
      ) {
        this.which_data = false
        this.switch_disable = false
      }
    }
    if (this.$store.state.auth.ActiveUser.view_all) {
      this.switch_disable = false
    }
    // this.getRecords()

    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])

    this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])
  },
  destroyed() {

  },
  methods: {
    updateCustomFilter(field, value) {
      this.serverParams.columnFilters[field] = value
      this.serverParams.page = 1
      this.updateParams({})
    },
    activateFullText(longerText, title) {
      this.fullText = longerText
      this.showFullText = true
      this.fullTextTitle = title
    },
    showDateRangeFilter(show, field) {
      this.dateRangeFilter = show
      this.dateRangeField = field
    },
    resetDateRangeFilter(show, field) {
      this.dateRangeField = field
      delete this.serverParams.columnFilters[field]
      this.startEndDate = null
      this.updateParams({})
    },
    updateParams(newProps) {
      const prevColumnFilters = this.serverParams.columnFilters
      // check if applied filter was previously applied
      this.serverParams = { ...this.serverParams, ...newProps }
      if (prevColumnFilters.applied_on) {
        this.serverParams.columnFilters.applied_on = prevColumnFilters.applied_on
      }
      this.getRecords()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.serverParams.page = 1
      this.updateParams({ columnFilters: currentFilters })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
      this.serverParams.selectedAllRows = false
    },
    selectAllRows() {
      this.dataRows.forEach((row, index) => {
        this.$set(this.dataRows[index], 'vgtSelected', false)
        this.$set(this.dataRows[index], 'vgtSelected', true)
      })
      setTimeout(() => {
        this.serverParams.selectedAllRows = true
      }, 100)
    },

    submitted() {
      this.getRecords()
      this.popActive = !this.popActive
    },

    async getRecords() {
      this.isLoading = true
      let query = ''
      if (this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
        this.serverParams.extractCSV = true

        if (!this.$isEmpty(this.selectedRows)) {
          const selectedRowsIdArray = []
          this.selectedRows.forEach(row => {
            switch (this.$route.name) {
              case 'candidates-data':
                selectedRowsIdArray.push(row.id)
                break

              case 'jobs-data':
                selectedRowsIdArray.push(row.id)
                break

              case 'applications-data':
                selectedRowsIdArray.push(row.application_id)
                break

              case 'biweekly-data':
                selectedRowsIdArray.push(row.application_id)
                break

              case 'events-data':
                selectedRowsIdArray.push(row.application_id)
                break

              case 'crm-data':
                selectedRowsIdArray.push(row.id)
                break

              default:
                break
            }
          })

          this.serverParams.selectedRows = selectedRowsIdArray
        }
      } else if (this.exportingCSVAndResumes) {
        this.serverParams.extractCSVAndResumes = true

        if (!this.$isEmpty(this.selectedRows)) {
          const selectedRowsIdArray = []
          this.selectedRows.forEach(row => {
            selectedRowsIdArray.push(row.application_id)
          })

          this.serverParams.selectedRows = selectedRowsIdArray
        }
      }

      query = `?params=${encodeURIComponent(JSON.stringify(this.serverParams))}`
      const tableName = this.getCurrentTable()
      const outerThis = this

      const response = await this.$http
        .get(`/api/table/${tableName}/${this.which_data}${query}`, {
          responseType: 'stream',

          onDownloadProgress(progressEvent) {
            const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
            }

            if (uploadPercentage === 100) {
              setTimeout(() => {
                outerThis.$toast.dismiss(outerThis.exportingCSVIndex)
              }, outerThis.$longestTimeout)
            }
          },
        })

      if (this.exportingCSV) {
        const link = document.createElement('a')
        link.href = await this.$generateDownloadUrl(response.data.s3_file_name)
        link.setAttribute('download', response.data.s3_file_name) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (this.exportingCSVAndResumes) {
        const link = document.createElement('a')
        link.href = await this.$generateDownloadUrl(response.data.s3_file_name)
        link.setAttribute('download', response.data.s3_file_name) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else {
        let disableSort = []
        let disableFilter = []
        let customFilter = []
        let filterDropdownItems = {}

        switch (this.$route.name) {
          case 'candidates-data':
            this.$set(this, 'prefIndustry', response.data.distinct_options.preferred_industry)
            this.$set(this, 'prefWorkLocation', response.data.distinct_options.preferred_location)
            this.$set(this, 'preferredPositionOptions', response.data.distinct_options.preferred_position)
            break

          case 'jobs-data':
            this.$set(this, 'publishedFieldOptions', response.data.distinct_options.published_fields)
            this.$set(this, 'prefIndustry', response.data.distinct_options.industry)
            this.$set(this, 'employmentTypeOptions', response.data.distinct_options.employment_type)
            break

          case 'applications-data':
          case 'bi-weekly-data':
          case 'events-data':
            this.$set(this, 'prefIndustry', response.data.distinct_options.applicant_preferred_industry)
            this.$set(this, 'prefWorkLocation', response.data.distinct_options.applicant_preferred_location)
            this.$set(this, 'preferredPositionOptions', response.data.distinct_options.applicant_preferred_position)
            this.$set(this, 'employmentTypeOptions', response.data.distinct_options.employment_type)
            this.$set(this, 'jobIndustryOptions', response.data.distinct_options.job_industry)
            this.$set(this, 'jobFunctionsOptions', response.data.distinct_options.job_functions)
            break

          default:
            break
        }

        if (tableName === 'users') {
          disableSort = ['preferred_position', 'preferred_industries', 'preferred_job_type']
          disableFilter = []
          customFilter = ['date_of_birth', 'created_on', 'gender']
          filterDropdownItems = {
            gender: this.allGenders,
            // citizenship: this.allCitizenships,
            employment_status: this.employmentStatus,
            highest_qualification: this.highestQualifications,
            preferred_position: this.preferredPositionOptions,
            preferred_industry: this.prefIndustry,
            preferred_location: this.prefWorkLocation,
            preferred_job_type: this.preferredJobTypeOptions,
          }
        } else if (tableName === 'jobs') {
          disableSort = ['job_questions', 'published_fields', 'employment_type', 'industry']
          disableFilter = []
          customFilter = []
          filterDropdownItems = {
            job_type: this.preferredJobTypeOptions,
            employment_type: this.employmentTypeOptions,
            salary_cycle: this.salaryCycleOptions,
            industry: this.prefIndustry,
            active: this.activeOptions,
            published_fields: this.publishedFieldOptions,
          }
        } else if (['applications', 'biweekly', 'events'].includes(tableName)) {
          disableSort = ['applicant_preferred_position', 'applicant_preferred_location', 'applicant_preferred_industry', 'applicant_preferred_job_type', 'employment_type', 'follow_up_answers', 'answered_questions', 'applicant_employment_status']
          disableFilter = ['follow_up_answers', 'answered_questions']
          customFilter = ['applied_on']
          filterDropdownItems = {
            applicant_gender: this.allGenders,
            applicant_preferred_position: this.preferredPositionOptions,
            applicant_preferred_location: this.prefWorkLocation,
            applicant_preferred_industry: this.prefIndustry,
            applicant_preferred_job_type: this.preferredJobTypeOptions,
            applicant_employment_status: this.employmentStatus,
            applicant_highest_qualification: this.highestQualifications,
            job_type: this.preferredJobTypeOptions,
            employment_type: this.employmentTypeOptions,
            job_interview_type: this.interviewTypeOptionss,
          }
        }

        if (this.columnDefs.length < 5) {
          this.columnDefs = response.data.columns.map(item => {
            const newUserCols = {}
            // changedUser[key.charAt(0).toUpperCase() + key.slice(1)] = value;
            newUserCols.label = item.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase()).replace('Id', 'ID')
            newUserCols.width = '320px'
            newUserCols.field = item
            newUserCols.tdClass = 'text-wrap text-break flex-column max-cell-size'
            newUserCols.sortable = !(disableSort.includes(item))
            newUserCols.filterOptions = {
              customFilter: (customFilter.includes(item)),
              trigger: 'enter',
              enabled: !(disableFilter.includes(item)),
              placeholder: '',

              filterDropdownItems: filterDropdownItems[item],
            }
            return newUserCols
          })

          if (!['bi-weekly-data', 'crm-data'].includes(this.$route.name)) {
            this.columnDefs.unshift(
              {
                label: 'Actions',
                field: 'actions',
                minWidth: 200,
                sortable: false,
                type: 'rightAligned',
                // tdClass: 'text-right',
              },
            )
          }
        }

        this.dataRows = response.data.records
        this.totalRecords = response.data.totalRecords
        this.isLoading = false
      }

      const parent = document.querySelector('#exportToCSV')
      if (parent) {
        parent.querySelector('.progress-bar').style = `width: ${100}%;`

        setTimeout(() => {
          this.$toast.dismiss(this.exportingCSVIndex)
        }, this.$longestTimeout)
      }

      this.exportingCSV = false
      this.exportingCSVAndResumes = false
      this.serverParams.extractCSV = false
      this.serverParams.extractCSVAndResumes = false

      this.isLoading = false
      this.firstRun = false
      // .then(response => {

      // })
      // .catch(error => {
      //   this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the records. Please try again later!')
      //   this.serverParams.extractCSV = false
      //   this.isLoading = false
      //   this.firstRun = false
      // })
    },

    onColumnFilter(params) {
      if (!this.isDataFetched) {
        this.$set(this.serverParams, 'page', 1)
        this.updateParams(params)
        this.isLoading = false
        this.isDataFetched = true
      }
      setTimeout(() => {
        this.isLoading = false
        this.isDataFetched = false
      }, 3000)
    },
    paginationColFilter(current_table) {
      const normal_columns = []
      const ques_columns = []
      const visible = []
      const invisible = []
      for (const property of current_table) {
        for (const col in property) {
          if (this.shared_columns.includes(col)) {
            if (!normal_columns.includes(col)) {
              visible.push(col.toLowerCase())
              normal_columns.push(col)
            }
          } else if (!ques_columns.includes(col)) {
            visible.push(col.toLowerCase())
            ques_columns.push(col)
          }
        }
      }

      const all_columns = normal_columns.concat(ques_columns)

      for (const colm of this.main_columns) {
        if (!all_columns.includes(colm)) {
          invisible.push(colm.toLowerCase())
        }
      }

      if (visible.length === 0) {
        this.gridOptions.columnApi.setColumnsVisible(
          this.main_columns.map(v => v.toLowerCase()),
          true,
        )
      } else {
        function sleep(time) {
          return new Promise(resolve => setTimeout(resolve, time))
        }
        this.gridOptions.columnApi.setColumnsVisible(visible, true)

        sleep(500).then(() => {
          this.gridOptions.columnApi.setColumnsVisible(invisible, false)
        })
      }
    },

    extractResumes() {
      const table_name = this.getCurrentTable()
      let selected = this.gridApi.getSelectedRows()
      if (selected.length > 0) {
        selected = this.gridApi.getSelectedRows()
      } else {
        this.gridApi.selectAllFiltered()
        selected = this.gridApi.getSelectedRows()
      }
      this.userids = []
      const all_ids = []
      for (const item of selected) {
        let record_id = ''
        if ('ID' in item) {
          record_id = item.ID
        } else if ('Application ID' in item) {
          record_id = item['Application ID']
        } else {
          record_id = item._id
        }

        if (!this.userids.includes(record_id)) {
          const data = record_id
          this.userids.push(data)
        }
      }

      this.resumeData(table_name)
    },
    changeAction(text) {
      this.action_text = text
      switch (text) {
        case 'Add Record':
          this.action_view = 'success'
          this.action_icon = 'add'
          break
        case 'Delete Record':
          this.action_view = 'danger'
          this.action_icon = 'delete'

          break
        case 'Broadcast':
          this.action_view = 'info'
          this.action_icon = 'info_outline'
          break
        case 'Actions':
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
          break
        default:
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
      }

      this.performAction()
    },
    performAction() {
      if (this.action_text === 'Delete Record') {
        const rows = this.gridApi.getSelectedRows()
        if (!rows || !rows.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Row Selection.',
                icon: 'CheckCircleIcon',
                text: 'Please select at least one row to proceed.',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )
        } else {
          const selected = this.gridApi.getSelectedRows()
          let message = ''
          let count = 1
          for (const item of selected) {
            this.items_deletion.push(item._id)
            message += `\n ${item._id}\n\n`
            count += 1
          }
          this.confirmDeleteRecord(message)
        }
      } else if (this.action_text === 'Add Record') {
        this.editDataManagerRow = null
        this.popActive = true
        // this.popActiveInitInputValue()
      } else if (this.action_text === 'Broadcast') {
        this.showBroadcastModal = !this.showBroadcastModal
      }
    },
    exportToCSV() {
      // retrieve data from db

      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 99999999 },
      )

      this.exportingCSV = true
      this.getRecords()
    },
    exportToCSVAndResumes() {
      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Compiling CSV and Resumes',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 99999999 },
      )

      this.exportingCSVAndResumes = true
      this.getRecords()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    deleteRowData() {
      const table_name = this.getCurrentTable()
      const company = this.$store.state.auth.ActiveUser.company_name
      const del_array = []
      for (const item of this.items_deletion) {
        del_array.push(item)
      }
      this.items_deletion = []

      return new Promise((resolve, reject) => {
        this.$http
          .delete(`/api/table/${company}/${table_name}`, {
            data: { all_ids: del_array },
          })
          .then(response => {
            const cleaner = response.data

            if (cleaner.success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'CheckCircleIcon',
                    text: cleaner.message,
                    variant: 'success',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.removeVrows()
            }
            resolve(response)
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Update',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            reject(error)
          })
      })
    },
    removeVrows() {
      //
    },

    updateRowData(_id, new_data, edit_column) {
      const table_name = this.getCurrentTable()
      const company = this.$store.state.auth.ActiveUser.company_name
      return new Promise((resolve, reject) => {
        this.$http
          .put(`/api/table/${company}/${table_name}`, {
            data_id: _id,
            col_data: new_data,
            col_name: edit_column,
          })
          .then(response => {
            const cleaner = response.data
            if (cleaner.success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'CheckCircleIcon',
                    text: cleaner.message,
                    variant: 'success',
                    position: 'center',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            resolve(response)
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            reject(error)
          })
      })
    },
    confirmDeleteRecord(record_names) {
      this.$bvModal
        .msgBoxConfirm(
          `You are about to delete the following records : \n ${record_names}`,
          {
            title: 'Confirm Deletion',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteRowData()
          }
        })
    },
    resumeData(table) {
      const company = this.$store.state.auth.ActiveUser.company_name
      return new Promise((resolve, reject) => {
        const resume_array = []
        for (const item of this.userids) {
          resume_array.push(item)
        }
        this.gridApi.exportDataAsCsv({ onlySelected: true })

        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'File Download',
              icon: 'CheckCircleIcon',
              text: 'Zip file is downloading in the background. Please wait a few minutes if you are downloading all files.',
              variant: 'warning',
              position: 'center',
              // progress: 0,
              // progressId: 'exportResumeAndCSV',
            },
          },
          { timeout: this.$longestTimeout },
        )

        const data = {
          userids: resume_array,
          table_name: table,
        }

        const { token } = this.$store.state.auth.ActiveUser

        // this.$http
        //   .post(`${this.$http.defaults.baseURL}/api/resumes/${company}`, data, {
        //     onUploadProgress: function(progressEvent) {
        //       const uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total ) * 100 ))
        //       const parent = document.querySelector('#exportResumeAndCSV')
        //       parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
        //     }.bind(this),
        //   })
        fetch(`${this.$http.defaults.baseURL}/api/resumes/${company}`, {
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            const fileName = 'applications.zip'
            if (!window.WritableStream) {
              streamSaver.WritableStream = WritableStream
              window.WritableStream = WritableStream
            }
            const fileStream = streamSaver.createWriteStream(fileName)
            const readableStream = response.body

            // More optimized
            if (readableStream.pipeTo) {
              return readableStream.pipeTo(fileStream)
            }

            window.writer = fileStream.getWriter()

            const reader = response.data.getReader()

            const pump = () => reader
              .read()
              .then(res => (res.done ? writer.close() : writer.write(res.value).then(pump)))

            pump()
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
      })
    },
    dataExtractor() {
      this.isLoading = true
      const table_name = this.getCurrentTable()

      if (table_name === 'applications') {
        this.hideAction = true
        this.hideResume = false
        this.show_switch = true
      } else if (table_name === 'biweekly') {
        this.hideAction = true
        this.hideResume = true
      } else if (table_name === 'users') {
        this.hideAction = false
        this.hideResume = false
        this.show_switch = false
      } else if (table_name === 'jobs') {
        this.hideAction = false
        this.hideResume = false
        this.show_switch = true
      } else if (table_name === 'events') {
        this.hideAction = true
        this.hideResume = false
      } else if (table_name === 'events-applications') {
        this.hideAction = true
        this.hideResume = true
        this.show_switch = true
      } else if (table_name === 'scheduled_jobs') {
        this.show_switch = false
      } else {
        this.hideAction = false
        this.hideResume = true
      }
      const company = this.$store.state.auth.ActiveUser.company_name

      // server pagination
      const query = ''

      this.$http
        .get(`/api/table/${company}/${table_name}/${this.which_data}`)
        .then(response => {
          if (this.exportingCSV) {
            const headers = []
            this.columnDefs.forEach(column => {
              headers.push({
                headerName: column.headerName,
                columnName: column.field,
              })
            })

            this.$downloadCSV(response.data.records, `${table_name} - ${this.$formatDatetime(new Date())}.csv`, headers)
            this.exportingCSV = false
            this.isLoading = false
          } else {
            this.records = response.data.records

            this.columns = response.data.columns
            this.main_columns = response.data.columns

            if (response.data.pagination) {
              this.pagination = response.data.pagination // server pagination
            }

            // this.shared_columns = response.data.columns.filter(
            //   col => !col.includes('?')
            //     && !col.toLowerCase().includes('yes')
            //     && !col.toLowerCase().includes('y/n')
            //     && !col.toLowerCase().includes('please')
            //     && !col.toLowerCase().includes('nil')
            //     && !col.toLowerCase().includes('2')
            //     && !col.toLowerCase().includes('q1')
            //     && !col.toLowerCase().includes('q2'),
            // )

            // if (company === 'e2i') {
            //   this.shared_columns = [
            //     'userid',
            //     'fullname',
            //     'Nric No',
            //     'gender',
            //     "User's Phone Number",
            //     'email',
            //     "User's Employment Status",
            //     "User's Preferred Location",
            //     'highest qualification',
            //     "User's Preferred Industry",
            //     "User's Preferred Position",
            //     "User's Preferred Job Type",
            //     'citizenship',
            //     'applied On',
            //     '_id',
            //     'position',
            //     'poster Name',
            //     'poster_id',
            //     'description',
            //     'type',
            //     'requirements',
            //     'employer',
            //     'uen No',
            //     'vacancies',
            //     'salary',
            //     'days',
            //     'time',
            //     'location',
            //     'union',
            //     'coach',
            //     'team',
            //     'post_channel',
            //     'taken',
            //     'posted On',
            //     'sembawang',
            //     'closed',
            //     'interview Status',
            //     'interview Feedback',
            //     'rating_attitude',
            //     'rating_communication',
            //     'rating_cultural_fit',
            //     'rating_experience',
            //     'rating_skills_and_knowledge',
            //   ]
            // }

            this.formData = this.formArray(response.data.columns)
            this.isLoading = false
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$handleErrorResponse(error)
        })

      // if (!moduleDataProcessing.isRegistered) {
      //   this.$store.registerModule("dataProcessing", moduleDataProcessing);
      //   moduleDataProcessing.isRegistered = true;
      // }
      //  let company = this.$store.state.auth.ActiveUser.company_name;
      //
      // this.$store
      //   .dispatch("dataProcessing/fetchRecords", table_name, company)
      //   .then((res) => {

      //   })
      //   .catch((err) => {});
    },
    formArray(columns) {
      const array = []
      const unwanted = ['Account Created On', 'Updated On', '_id']
      for (const item of columns) {
        if (!unwanted.includes(item)) {
          array.push({
            label: this.capitalizeFirstLetter(item),
            value: '',
          })
        }
      }

      return array
    },
    getCurrentTable() {
      let table_name = ''
      const table = this.$route.name
      switch (table) {
        case 'candidates-data':
          table_name = 'users'
          break
        case 'jobs-data':
          table_name = 'jobs'
          break
        case 'scheduled-jobs-data':
          table_name = 'scheduled_jobs'
          break
        case 'applications-data':
          table_name = 'applications'
          break
        case 'bi-weekly-data':
          table_name = 'biweekly'
          break
        case 'crm-data':
          table_name = 'crm'
          break
        case 'candidates-events-applications':
          table_name = 'events-applications'
          break
        default:
          table_name = 'events'
      }
      return table_name
    },

    selectUser(value) {
      this.$set(this.applicationData, 'user_id', value.id)
      this.$set(this.applicationData, 'user_fullname', value.fullname)
    },
    selectJob(value) {
      this.$set(this.applicationData, 'job_id', value.id)
      this.$set(this.applicationData, 'job_position', value.position)
      this.$set(this.applicationData, 'job_questions', value.questions)

      this.applicationData.answers = []
      value.questions.forEach(question => {
        this.applicationData.answers.push({
          question,
          answer: null,
        })
      })
      // this.selectedJobs = !this.$isEmpty(value) ? value.id : null
      // this.$set(this.switchCompanyData, 'selected_company', !this.$isEmpty(value) ? value.id : null)
    },
    getJobInfo(id) {
      this.$http
        .get(`/api/bot-job/${id}`)
        .then(response => {
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    submitApplicationData() {
      if (this.$isEmpty(this.editDataManagerRow)) {
        const all_data = {
          // id: this.applicationId,
          job_id: this.applicationData.job_id,
          user_id: this.applicationData.user_id,
          interviewed: false,
          save_nric: false,
          questions_answers: this.applicationData.job_questions,
          // resume_file: this.resume_file ? this.resume_file : currentUser.resume_file,
        }

        let url = ''
        if (this.$route.name === 'events-data') {
          url = '/api/book'

          all_data.interview_status = this.applicationData.interview_status
          all_data.interview_date_time = this.applicationData.interview_date_time
        } else {
          url = '/api/bot/book'
        }

        this.$http
          .post(`${url}`, all_data)
          .then(response => {
            const { success } = response.data
            if (success) {
              //   this.$router.go()
              this.$toastSuccess('Operation Success', 'Application created successfully')

              this.submitted()
            } else {
              this.$toastDanger()
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      } else {
        const all_data = {
          // id: this.applicationId,
          job_id: this.applicationData.job_id,
          user_id: this.applicationData.user_id,
          interviewed: false,
          save_nric: false,
          questions_answers: this.applicationData.job_questions,
          // resume_file: this.resume_file ? this.resume_file : currentUser.resume_file,
        }

        let url = ''
        if (this.$route.name === 'events-data') {
          url = `/api/book/${this.editDataManagerRow.application_id}`

          all_data.interview_status = this.applicationData.interview_status
          all_data.interview_date_time = this.applicationData.interview_date_time
        } else {
          url = `/api/bot/book/${this.editDataManagerRow.application_id}`
        }

        this.$http
          .put(`${url}`, all_data)
          .then(response => {
            const { success } = response.data
            if (success) {
              //   this.$router.go()
              this.$toastSuccess('Operation Success', 'Application updated successfully')

              this.submitted()
            } else {
              this.$toastDanger()
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      }
    },

    editRecord(row) {
      this.popActive = true
      this.editDataManagerRow = row

      if (['events-data', 'applications-data'].includes(this.$route.name)) {
        this.populateEditForm()
      }
    },

    populateEditForm() {
      let url = `/api/bot-event-data/${this.editDataManagerRow.application_id}`
      if (['applications-data'].includes(this.$route.name)) {
        url = `/api/bot-application-data/${this.editDataManagerRow.application_id}`
      }

      this.$http
        .get(url)
        .then(response => {
          const { output, success } = response.data
          this.$set(this, 'applicationData', output)

          this.repopulateInputWithFormData()

          setTimeout(() => {
            this.applicationData.job_questions = output.question_answer
          }, 1000)
        })
        .catch(() => {})
    },
    repopulateInputWithFormData() {
      this.$refs.refBotJobIdSelect.getJobs(this.applicationData.position, false)
      const job = {
        id: this.applicationData.job_id,
        position: this.applicationData.position,
        questions: [],
      }
      this.$refs.refBotJobIdSelect.selected_jobs = job

      this.$refs.refBotUserIdSelect.getUsers(this.applicationData.fullname, false)
      const user = {
        id: this.applicationData.applicant_id,
        fullname: this.applicationData.fullname,
        email_address: this.applicationData.email_address,
      }
      this.$refs.refBotUserIdSelect.selected_users = user
    },

    updateFormData() {

    },

    checkForm() {

    },
    setApplicationId() {

    },
  },
}
</script>

<style lang="scss">
.max-cell-size {
  max-width: 300px!important;
}

#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.dropdown-button-container {
  display: flex;
  align-items: center;
  .btnx {
    border-radius: 5px 0px 0px 5px;
  }
  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.blur {
  filter: blur(16px);
}
.hide {
  display: none;
}
</style>

<style lang="scss" scoped>
.card-body {
  // reduce gap on b-card
  padding-bottom: 0;
  padding-top: 1rem;
  padding-inline: 1rem;
}
.scroll-container {
  overflow-x: scroll; /* or overflow-x: scroll; */
  white-space: wrap; /* optional, to prevent wrapping of content */
}
</style>
