<template>
  <b-modal
    v-model="record_popup"
    centered
    hide-footer
    title="Add Record"
  >
    <div class="vx-row">
      <div
        v-for="value in formDetails"
        :key="value.label"
        class="vx-col w-1/2 mb-6"
      >
        <div
          v-if="value.label !== '_id'"
          class=""
        >
          <!-- <b-alert active="{{value.alert}}" variant='danger'> {{value.label}} </b-alert>  -->

          <b-form-input
            v-if="value.label === 'Userid'"
            :id="value.label"
            required
            type="number"
            class="w-full"
            :label="value.label"
            :value="value.value"
            @change="updateInput"
          />
          <b-form-input
            v-else-if="value.label === 'Phone Number'"
            :id="value.label"
            type="number"
            class="w-full"
            :label="value.label"
            :value="value.value"
            @change="updateInput"
          />
          <b-form-input
            v-else-if="value.label === 'Email'"
            :id="value.label"
            type="email"
            class="w-full"
            :label="value.label"
            :value="value.value"
            @change="updateInput"
          />
          <div
            v-else-if="
              [
                'Citizenship',
                'Gender',
                'Employment Status',
                'Highest Qualification',
              ].includes(value.label)
            "
            class="w-full"
          >
            <label class="b-form-input--label">{{ value.label }}</label>
            <v-select
              v-model="value.value"
              danger-text="This field is required"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              class="w-full"
              :options="select_dicts[value.label]"
              label="value"
              :reduce="value => value.value"
            />
          </div>

          <div
            v-else-if="
              [
                'Preferred Industry',
                'Preferred Job Type',
                'Preferred Location',
              ].includes(value.label)
            "
            class="w-full"
          >
            <label class="b-form-input--label">{{ value.label }}</label>
            <v-select
              v-model="value.value"
              multiple
              :options="select_dicts[value.label]"
              label="value"
              danger-text="Please select your citizenship(required)"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
              class="w-full"
              :reduce="value => value.value"
            />
          </div>

          <b-form-input
            v-else
            :id="value.label"
            class="w-full"
            :label="value.label"
            :value="value.value"
            @change="updateInput"
          />
        </div>
      </div>
    </div>
    <div class="vx-row w-full">
      <div class="vx-col mx-auto w-1/2">
        <b-button
          variant="success"
          class="w-full mr-3 mb-2"
          @click="submitData"
        >Add Record</b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormGen',
  props: {
    formDetails: Array,
    popupActive: { type: Boolean, default: false },
  },
  data() {
    return {
      all_inputs: {},
      select_dicts: {
        Citizenship: [
          {
            text: 'Singaporean',
            value: 'Singaporean',
          },
          {
            text: 'Singaporean PR',
            value: 'Singaporean PR',
          },
          {
            text: 'Others',
            value: 'Others',
          },
        ],

        'Employment Status': [
          {
            text: 'Unemployed for less than 6 months',
            value: 'unemployed<6mo',
          },
          {
            text: 'Unemployed for more than 6 months',
            value: 'unemployed>6mo',
          },
          {
            text: 'Employed',
            value: 'employed',
          },
        ],
        Gender: [
          {
            text: 'Male',
            value: 'Male',
          },
          {
            text: 'Female',
            value: 'Female',
          },
          {
            text: 'Others',
            value: 'Others',
          },
        ],
        'Highest Qualification': [
          {
            text: 'PLSE',
            value: 'PLSE',
          },
          {
            text: 'N-levels',
            value: 'N-levels',
          },
          {
            text: 'O-levels',
            value: 'O-levels',
          },
          {
            text: 'Nitec',
            value: 'Nitec',
          },
          {
            text: 'Higher Nitec',
            value: 'Higher Nitec',
          },
          {
            text: 'Diploma',
            value: 'Diploma',
          },
          {
            text: 'A-levels',
            value: 'A-levels',
          },
          {
            text: 'Advanced Diploma',
            value: 'Advanced Diploma',
          },
          {
            text: 'Degree',
            value: 'Degree',
          },
          {
            text: 'Masters',
            value: 'Masters',
          },
          {
            text: 'PhD',
            value: 'PhD',
          },
          {
            text: 'Others',
            value: 'Others',
          },
        ],
        'Preferred Industry': [
          {
            text: 'Accommodation and food service activities',
            value: 'Accommodation and food service activities',
          },
          {
            text: 'Administrative and support service activities',
            value: 'Administrative and support service activities',
          },
          { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
          {
            text: 'Arts, entertainment and recreation',
            value: 'Arts, entertainment and recreation',
          },
          { text: 'Construction', value: 'Construction' },
          { text: 'Education', value: 'Education' },
          {
            text: 'Electricity,gas,steam and air-conditioning supply',
            value: 'Electricity,gas,steam and air-conditioning supply',
          },
          {
            text: 'eSports',
            value: 'eSports',
          },
          {
            text: 'Financial and insurance activities',
            value: 'Financial and insurance activities',
          },
          {
            text: 'Health and social services',
            value: 'Health and social services',
          },
          {
            text: 'Information and communications',
            value: 'Information and communications',
          },
          { text: 'Manufacturing', value: 'Manufacturing' },
          { text: 'Mining and quarrying', value: 'Mining and quarrying' },
          {
            text: 'Other service activities',
            value: 'Other service activities',
          },
          {
            text: 'Professional, scientific and technical activities',
            value: 'Professional, scientific and technical activities',
          },
          {
            text: 'Public administration and defence',
            value: 'Public administration and defence',
          },
          { text: 'Real estate activities', value: 'Real estate activities' },
          {
            text: 'Transportation and storage',
            value: 'Transportation and storage',
          },
          {
            text: 'Water supply;sewerage waste management and remediation activities',
            value:
              'Water supply;sewerage waste management and remediation activities',
          },
          {
            text: 'Wholesale and retail trade',
            value: 'Wholesale and retail trade',
          },

        ],

        'Preferred Job Type': [
          { text: 'Part-time', value: 'parttime' },
          { text: 'Full-time', value: 'fulltime' },
          { text: 'Internship/Traineeship', value: 'internship' },
          { text: 'Freelance', value: 'freelance' },
          { text: 'Permanent', value: 'permanent' },
          { text: 'Contract', value: 'contract' },
          { text: 'Flexi-work', value: 'flexiwork' },
          { text: 'Temporary', value: 'temporary' },
        ],

        'Preferred Location': [
          {
            text: 'North',
            value: 'North',
          },
          {
            text: 'South',
            value: 'South',
          },
          {
            text: 'East',
            value: 'East',
          },
          {
            text: 'West',
            value: 'West',
          },
        ],
      },
    }
  },
  computed: {
    record_popup() {
      return this.popupActive
    },
    record_popup: {
      get() {
        return this.popupActive
      },
      set(newValue) {
        return newValue
      },
    },
  },
  methods: {
    updateInput(event) {
      this.updateForm(event.target.id, event.target.value)
    },
    updateForm(col_name, col_value) {
      for (const i in this.formDetails) {
        if (this.formDetails[i].label === col_name) {
          this.formDetails[i].value = col_value
          break
        }
      }
    },
    submitData() {
      let proceed = true
      for (const i in this.formDetails) {
        let out_value = this.formDetails[i].value
        if (
          this.formDetails[i].label === 'Userid'
          || this.formDetails[i].label === 'Phone_number'
        ) {
          out_value = Number(this.formDetails[i].value)
        }
        this.all_inputs[this.formDetails[i].label] = out_value

        if (
          !this.formDetails[i].value
          || this.formDetails[i].value === 'Not Set'
        ) {
          proceed = false
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Required Field',
                icon: 'AlertCircleIcon',
                text: 'Please fill all fields before adding a new record.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          break
        }
      }
      if (proceed) {
        this.addRowData()
      }
    },
    addRowData() {
      const table_name = this.getCurrentTable()
      const company = this.$store.state.auth.ActiveUser.company_name
      return new Promise((resolve, reject) => {
        this.$http
          .post(`/api/table/${company}/${table_name}`, {
            data: this.all_inputs,
          })
          .then(response => {
            const cleaner = response.data
            if (cleaner.success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'AlertCircleIcon',
                    text: cleaner.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.onClickCloseButton()
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'AlertCircleIcon',
                    text: cleaner.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            resolve(response)
          })
          .catch(error => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Update',
                  icon: 'AlertCircleIcon',
                  text: 'Operation failed! Please try again later!',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            reject(error)
          })
      })
    },
    getCurrentTable() {
      let table_name = ''
      const table = this.$route.name
      switch (table) {
        case 'Candidates Data':
          table_name = 'users'
          break
        case 'Jobs Data':
          table_name = 'jobs'
          break
        case 'Scheduled Jobs Data':
          table_name = 'scheduled_jobs'
          break
        case 'Applications Data':
          table_name = 'applications'
          break
        default:
          table_name = 'users'
      }
      return table_name
    },
    uncapFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1)
    },
    onClickCloseButton() {
      this.$emit('closePopup')
    },
  },
}
</script>
