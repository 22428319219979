<template>
  <div class="w-100">
    <!-- <form-wizard
      ref="wizard"
      v-model="activeTab"
      :title="null"
      :subtitle="null"
      color="#FF9F43"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-change="stepChange"
      @on-comp="submit"
    >
      <tab-content
        title="Account"
        class="mb-1"
      >
        <UserManagerAddUser 
          ref="refUserManagerAddUser"
          :edit-section="editSection"
          :initial-edit-data="editData"
          @submitted="submittedUserManagerAddUser"
        />
      </tab-content>

      <tab-content
        title="Profile"
        class="mb-1"
      >
      </tab-content>
    </form-wizard> -->

    <validation-observer ref="registerForm">
      <div>
        <b-row class="mx-1 my-2">
          <!-- fullname -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Full Name"
              label-for="fullname"
            >
              <b-form-input
                id="fullname"
                v-model="candidateData.fullname"
                rows="4"
                class="w-full"
                :state="!checkFullName(candidateData.fullname)[0]"
                placeholder="Full Name"
              />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Email Address"
              label-for="email_address"
            >
              <b-form-input
                id="email_address"
                v-model="candidateData.email_address"
                class="w-full"
                :state="candidateData.email_address.length > 4 &&
                  candidateData.email_address.includes('@') &&
                  candidateData.email_address.includes('.')
                  ? true
                  : false"
                placeholder="Email Address"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label-for="register-password"
              label="Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="candidateData.userPassword"
                    autocomplete="new-password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label-for="register-confirm-password"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-confirm-password"
                    v-model="candidateData.confirmPassword"
                    autocomplete="new-password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-confirm-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- phone_number -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Phone Number"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                v-model="candidateData.phone_number"
                rows="4"
                class="w-full"
                placeholder="Phone Number"
              />
            </b-form-group>
          </b-col>

          <!-- nric_no -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="NRIC No"
              label-for="nric_no"
            >
              <b-form-input
                id="nric_no"
                v-model="candidateData.nric_no"
                rows="4"
                class="w-full"
                placeholder="NRIC No"
              />
            </b-form-group>
          </b-col>

          <!-- telegram_id -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Telegram ID"
              label-for="telegram_id"
            >
              <b-form-input
                id="telegram_id"
                v-model="candidateData.telegram_id"
                rows="4"
                class="w-full"
                placeholder="Telegram ID"
              />
            </b-form-group>
          </b-col>

          <!-- gender -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
            >
              <v-select
                id="Gender"
                v-model="candidateData.gender"
                :state="
                  candidateData.gender !== 'Not Set' &&
                    candidateData.gender !== ''
                    ? true
                    : false
                "
                danger-text="Please select your gender(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="allGenders"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- highest_qualification -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Highest Qualification"
              label-for="highest_qualification"
            >
              <!-- <b-form-input
                id="highest_qualification"
                rows="4"
                class="w-full"
                placeholder="Highest Qualification"
              /> -->

              <v-select
                v-model="candidateData.highest_qualification"
                :danger="
                  candidateData.highest_qualification !== 'Not Set' &&
                    candidateData.highest_qualification !== ''
                    ? false
                    : true
                "
                danger-text="Please select your highest qualification(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="highestQualifications"
                label="value"
                :reduce="value => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- date_of_birth -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Date of birth"
              label-for="date_of_birth"
            >
              <flat-pickr
                v-model="candidateData.date_of_birth"
                class="form-control"
                :config="dobConfig"
                placeholder="Date of birth"
              />
            </b-form-group>
          </b-col>

          <!-- emploment_status -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Employment Status"
              label-for="employment_status"
            >
              <v-select
                v-model="candidateData.employment_status"
                :danger="
                  candidateData.employment_status !== 'Not Set' &&
                    candidateData.employment_status !== ''
                    ? false
                    : true
                "
                danger-text="Please select your employment status(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="employmentStatus"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- citizenship -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Citizenship"
              label-for="citizenship"
            >
              <v-select
                v-model="candidateData.citizenship"
                :danger="
                  candidateData.citizenship !== 'Not Set' &&
                    candidateData.citizenship !== ''
                    ? false
                    : true
                "
                danger-text="Please select your citizenship(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                :options="citizenship"
                label="value"
                :reduce="(value) => value.value"
              />

              <v-select
                v-if="candidateData.citizenship === 'Others'"
                v-model="candidateData.citizenship"
                danger-text="Please select your citizenship(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full mt-1"
                :options="allCitizenships"
                label="value"
                :reduce="value => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_work_location -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Working Location"
              label-for="prefered_working_location"
            >
              <v-select
                v-model="candidateData.preferred_location"
                :danger="
                  candidateData.preferred_location.length > 0 &&
                    candidateData.preferred_location !== 'Not Set' &&
                    candidateData.preferred_location !== ''
                    ? false
                    : true
                "
                danger-text="Please select your preffered work location(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                class="w-full"
                multiple
                :options="prefWorkLocation"
                :reduce="(value) => value.value"
                label="value"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_position -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Preferred Job Position (Press Enter to lock in each Preferred Job Position)"
              label-for="prefered_position"
            >
              <!-- <b-form-input
                id="prefered_position"
                rows="4"
                class="w-full"
                placeholder="Prefered Position"
              /> -->

              <v-select
                id="Preferred Job Position"
                v-model="candidateData.preferred_position"
                class="w-full"
                taggable
                multiple
                no-drop
                :map-keydown="selectHandlers"
                :options="preferred_position_options"
                :create-option="option => option.toLowerCase()"
                :danger="
                  candidateData.preferred_position &&
                    candidateData.preferred_position !== 'Not Set' &&
                    candidateData.preferred_position !== ''
                    ? false
                    : true
                "
                danger-text="Please select your preffered job position(required)"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_working_industry -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Working Industry"
              label-for="prefered_working_induxtry"
            >
              <v-select
                v-model="candidateData.preferred_industry"
                class="w-full"
                multiple
                :danger="candidateData.preferred_industry.length > 0 ? false : true"
                danger-text="Please select your preffered job industry(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="prefIndustry"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- residential_postal_code -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Residendial Postal Code"
              label-for="residential_postal_code"
            >
              <b-form-input
                id="residential_postal_code"
                v-model="candidateData.postal_code"
                rows="4"
                class="w-full"
                placeholder="Residential Postal Code"
              />
            </b-form-group>
          </b-col>

          <!-- preferred_job_type -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Prefered Job Type"
              label-for="prefered_job_type"
            >
              <v-select
                v-model="candidateData.preferred_job_type"
                class="w-full"
                multiple
                :danger="candidateData.preferred_job_type.length > 0 ? false : true"
                danger-text="Please select your preffered job type(required)"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
                :options="job_types"
                label="value"
                :reduce="(value) => value.value"
              />
            </b-form-group>
          </b-col>

          <!-- notice_period -->
          <b-col
            v-if="false"
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Notice Period"
              label-for="notice_period"
            >
              <div 
                id="notice_period" 
                class="d-flex flex-gap-md justify-content-between w-100"
              >
                <b-form-input
                  v-model="candidateData.availability_number"
                  class="w-50"
                  type="text"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  :state="
                    candidateData.availability_number ? true : false
                  "
                  danger-text="Please select how many days, weeks, months when you are available to start the job"
                  val-icon-danger="icon-x"
                  val-icon-pack="feather"
                />

                <v-select
                  v-model="candidateData.availability_period"
                  :danger="
                    candidateData.availability_period !== 'Not Set' &&
                      candidateData.availability_period !== ''
                      ? false
                      : true
                  "
                  danger-text="Please select the period when you are available to start the job"
                  val-icon-danger="icon-x"
                  val-icon-pack="feather"
                  class="w-50"
                  :options="all_availability"
                  label="value"
                  :reduce="value => value.value"
                />

              </div>
            </b-form-group>
          </b-col>

          <!-- team -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Team"
              label-for="team"
            >
              <b-form-input
                id="team"
                v-model="candidateData.team"
                rows="4"
                class="w-full"
                placeholder="Team"
              />
            </b-form-group>
          </b-col>

          <!-- union -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Union"
              label-for="union"
            >
              <b-form-input
                id="team"
                v-model="candidateData.union"
                rows="4"
                class="w-full"
                placeholder="Union"
              />
            </b-form-group>
          </b-col>

          <!-- coach -->
          <b-col
            cols="6"
            class="px-1 mb-1"
          >
            <b-form-group
              label="Coach"
              label-for="coach"
            >
              <b-form-input
                id="team"
                v-model="candidateData.coach"
                rows="4"
                class="w-full"
                placeholder="Coach"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="false">
          <b-col class="px-r my-1">
            <b-button
              class="mx-2"
              variant="primary"
              @click="$refs.uploadImgInput.click()"
            >
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
              <input
                ref="uploadImgInput"
                type="file"
                class="hidden"
                accept="image/*"
              >
              <span class="d-none d-sm-inline">Upload Resume</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="false" class="px-3 mx-1 my-2 float-right w-100">
          <b-col
            cols="12"
            class="mx-1 my-1"
          >
            <b-form-checkbox
              v-model="candidateData.send_through_system"
              class="w-full"
            >Send login details through HeadHunters HQ system</b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="w-100 mb-1"
          >
            <b-button
              variant="warning"
              class="w-100"
              @click="generatePass"
            >
              Generate new password
            </b-button>
          </b-col>

          <b-col
            v-if="!candidateData.send_through_system && show_pass"
            cols="12"
            class="w-100 mb-1"
          >
            <b-form-input
              v-model="generated_pass"
              :class="`w-full`"
              @click="copyTextToClipboard()"
            />
            <b-alert
              class="mt-1 mx-1"
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span
                  class="ml-25"
                >Please copy the new generated password because it wont be
                  available once you close this screen.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="mx-1 my-2 float-right">
          <b-button 
            variant="primary"
            @click="submit"
          >
            {{ $isEmpty(editRecord) ? 'Add Record': 'Update Record' }}
          </b-button>
        </b-row>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import useJwt from '@/auth/jwt/useJwt'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'


import {
  orderBy,
} from 'lodash'

import { allIndustries, highestQualifications, allCitizenships } from '@/constants/datastore'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import RegistrationForm from '../../authentication/registration/RegistrationForm.vue'
import UserManagerAddUser from '../../user-management-system/components/UserManagerAddUser.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,

    FormWizard,
    TabContent,

    UserManagerAddUser,
    RegistrationForm,
  },

  mixins: [togglePasswordVisibility],

  props: {
    editRecord: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      activeTab: 0,

      editSection: 'add',
      editData: {},
      show_pass: false,
      generated_pass: '',

      candidateData: {
        fullname: '',
        email_address: '',
        preferred_location: [],
        preferred_industry: [],
        preferred_job_type: [],
        send_through_system: true,
      },

      sanitized_account_type: this.$AccountTypes.APPLICANT,

      dobConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },

      // field input options
      allGenders: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],

      employmentStatus: [
        {
          text: 'Unemployed for less than 6 months',
          value: 'Unemployed for less than 6 months',
        },
        {
          text: 'Unemployed for more than 6 months',
          value: 'Unemployed for more than 6 months',
        },
        {
          text: 'Employed',
          value: 'Employed',
        },
      ],

      citizenship: [
        {
          text: 'Singaporean',
          value: 'Singaporean',
        },
        {
          text: 'Singaporean PR',
          value: 'Singaporean PR',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],
      allCitizenships: [],

      all_availability: [
        {
          text: 'Days',
          value: 'Days',
        },
        {
          text: 'Weeks',
          value: 'Weeks',
        },
        {
          text: 'Months',
          value: 'Months',
        },
      ],

      prefWorkLocation: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],

      job_types: [
        { text: 'Part-time', value: 'parttime'},
        { text: 'Full-time', value: 'fulltime'}
      ],

      employment_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
      ],
      interviewTypes: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },
      preferred_position_options: [],
      prefIndustry: [],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    this.generatePass()

    this.editData = {
      fullname: '',
      email_address: '',
      team: '',
      company: '',
      recruiter_number: '',
      agency_number: '',
      two_factor: false,
      whitelist: false,
      organization: '',
      view_all: false,
      send_through_system: false,
    }

    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    this.allCitizenships = orderBy(allCitizenships, ['value'], ['asc'])
    this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])

    if (this.$isNotEmpty(this.editRecord)) {
      this.populateEditData()
    }
  },

  methods: {
    populateEditData() {
      this.$http
        .get(`/api/bot-user/${this.editRecord.id}`)
        .then(response => {
          const { output, success } = response.data
          this.$set(this, 'candidateData', output)
        })
        .catch(() => {})
    },

    stepChange(prevIndex, currentIndex) {
      if (prevIndex === 0) {
        this.initializeJWT()
        // this.$refs.refUserManagerAddUser.saveChanges()
      }
      // if (prevIndex === 1) {
      //   this.saveProfile(false)
      // }
    },
    submit() {
      this.saveProfile(false)
    },

    selectHandlers: (map, vm) => ({
      ...map,
      50: e => {
        e.preventDefault()
      },
    }),

    submittedUserManagerAddUser(data) {
      this.candidateData = data
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (
        c
          ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16))
    },
    generatePass() {
      const uniq = this.uuidv4()
      this.generated_pass = uniq
      this.show_pass = true
    },
    copyTextToClipboard() {
      const text = this.generated_pass
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Copy Operation',
            icon: 'CheckCircleIcon',
            text: 'Text copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    async saveProfile(company = false, password = false) {
      if (typeof company === 'boolean') {
        if (this.$route.name === 'edit-corporate') {
          company = true
        }
        let proceed = true
        const { token } = this.$store.state.auth.ActiveUser
        this.$http.defaults.headers.common.Authorization = `Token ${token}`

        const preferred_job_type = []

        if (this.checkFullName(this.candidateData.fullname)[0]) {
          proceed = false
        }

        if (company === false) {
          this.candidateData.phone_number = this.candidateData.phone_number.toString()
          if (this.candidateData.phone_number.toString().length !== 8 && this.$route.name === 'edit-profile') {
            this.$toastDanger('Invalid phone number', 'The phone number that you have filled is invalid.')
            proceed = false
          }

          const form_data = this.candidateData

          form_data.password = null
          form_data.old_password = null
          // if (password) {
          //   form_data.password = this.userPassword
          //   form_data.old_password = this.oldPassword
          // }

          if (proceed) {
            const response = await this.$http.post('/api/bot/user', {
              user: form_data,
            })
            if (response.data.errors) {
              this.$toastDanger()
            } else {
              this.candidateData.avatar = response.data.profile_image
              if (this.candidateData.avatar) {
                // eslint-disable-next-line no-inner-declarations
                const imageUrl = await this.$generateDownloadUrl(this.candidateData.avatar)
                this.candidateData.avatar = imageUrl
                this.candidateData.telegram_id = response.data.telegram_id
              }

              this.candidateData.preferred_position = response.data.preferred_position

              if (response.data.telegram_id !== 0) {
                this.has_logged_telegram = true
              }
              // this.updateConfigInfo()
              this.$toastSuccess('Profile Update', 'Your profile has been updated successfully!')
              this.$emit('submitted', true)
            }

            this.isLoading = false
          }
        }
      }
    },

    checkFullName(fullname) {
      let message = ''
      let result = false
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

      if (!(fullname.length > 2)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      if (format.test(fullname)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      return [result, message]
    },
  },
}
</script>

<style lang="scss" scoped>

</style>